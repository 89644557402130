<template>
  <div>
    <div class="pxkeji-right-box">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :xxl="6" :xl="8">
            <a-form-item label="考试名称">
              <a-input placeholder="请输入" v-model="search.testName" />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="状态">
              <a-select v-model="search.examStatus" allowClear>
                <a-select-option value>全部</a-select-option>
                <a-select-option value="0">未发布</a-select-option>
                <a-select-option value="1">进行中</a-select-option>
                <a-select-option value="2">已结束</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8" :style="{ textAlign: 'left' }">
            <a-form-item>
              <a-button type="primary" @click="searchList">搜索</a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div>
        <pxkeji-btn
          name="添加"
          class="pxkeji_basic_btn"
          @click="showInfo({ id: 0 })"
          type="primary"
          :roles="['考试-考试添加']"
        />
        <pxkeji-btn
          name="批量发布"
          :disabled="selectRows.length == 0"
          class="pxkeji_basic_btn"
          :roles="['考试-考试修改状态']"
          @click="changeStatusBatch(1)"
        />
        <pxkeji-btn
          name="批量结束"
          :disabled="selectRows.length == 0"
          class="pxkeji_basic_btn"
          type="danger"
          :roles="['考试-考试修改状态']"
          @click="changeStatusBatch(2)"
        />
      </div>
    </div>

    <div>
      <a-table
        rowKey="id"
        size="small"
        bordered
        :columns="list.columns"
        :pagination="list.pagination"
        @change="onPageChange"
        :dataSource="list.datas"
        :loading="isloading"
        :rowSelection="{
          type: 'checkbox',
          columnWidth: 40,
          onChange: onRowChange,
          selectedRowKeys: selectRows,
        }"
        :locale="{ emptyText: '暂无数据' }"
        :scroll="{ y: list.tableHeight }"
      >
        <template slot="examType" slot-scope="examType">
          <a-tag v-if="examType == 1" color="red">正式考试</a-tag>
          <a-tag v-if="examType == 2" color="green">模拟考试</a-tag>
        </template>
        <template slot="trigger" slot-scope="trigger">
          <template v-if="trigger">
            <template v-if="trigger.triggerType == 0">
              学分({{ trigger.triggerSetting }})
            </template>
            <template v-if="trigger.triggerType == 1">
              学时({{ trigger.triggerSetting }})
            </template>
            <template v-if="trigger.triggerType == 2">关联课程</template>
            <template v-if="trigger.triggerType == 3">关联考试</template>
          </template>
          <template v-else>未设置</template>
        </template>
        <template slot="status" slot-scope="status">
          <a-tag v-if="status == 0" color="blue">未发布</a-tag>
          <a-tag v-else-if="status == 1" color="green">进行中</a-tag>
          <a-tag v-else color="red">已结束</a-tag>
        </template>
        <template slot="action" slot-scope="info">
          <pxkeji-btn
            name="编辑"
            :roles="['考试-考试修改']"
            size="small"
            @click="showInfo(info)"
            type="primary"
          />
          <pxkeji-btn
            name="试卷"
            :roles="['考试-试卷管理']"
            size="small"
            @click="showPaper(info.id, info.questionsCount)"
          />
          <pxkeji-btn
            name="考试条件"
            :roles="['考试-考试条件设置']"
            size="small"
            type="primary"
            @click="setTrigger(info.id)"
          />
          <a-popconfirm
            title="是否确认结束此考试"
            @confirm="changeStatus(info.id, 2)"
          >
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              v-if="info.examStatus == 1"
              name="结束"
              :roles="['考试-考试修改状态']"
              size="small"
              type="danger"
            />
          </a-popconfirm>
          <a-popconfirm
            title="是否确认发布此考试"
            @confirm="changeStatus(info.id, 1)"
          >
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              v-if="info.examStatus != 1"
              name="发布"
              :roles="['考试-考试修改状态']"
              size="small"
            />
          </a-popconfirm>
          <a-dropdown>
            <a-menu slot="overlay">
              <a-menu-item key="1" v-if="info.examStatus == 0">
                <a-popconfirm
                  title="是否确认删除此考试"
                  @confirm="delInfo(info.id)"
                >
                  <a-icon slot="icon" type="question-circle-o" />
                  <pxkeji-btn
                    name="删除"
                    :roles="['考试-考试删除']"
                    size="small"
                    type="danger"
                  />
                </a-popconfirm>
              </a-menu-item>
              <a-menu-item key="2" v-if="info.examStatus > 0">
                <pxkeji-btn
                  name="成绩"
                  :roles="[]"
                  @click="showsScore(info.id)"
                  size="small"
                />
              </a-menu-item>
            </a-menu>
            <a-button size="small" type="link"> ... </a-button>
          </a-dropdown>
        </template>
      </a-table>
    </div>

    <a-modal
      :title="addedit.title"
      v-model="addedit.visible"
      width="900px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <ExaminationInfo @callbackMethod="save" :infos="addedit.info" />
    </a-modal>
    <a-modal
      title="试卷列表"
      v-model="paperinfo.visible"
      width="1000px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <PaperList
        @callbackMethod="hide"
        :examinationId="paperinfo.id"
        :limit="paperinfo.limit"
      />
    </a-modal>
    <a-modal
      title="考试条件设置"
      v-model="triggerInfo.visible"
      width="800px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <Trigger @callbackMethod="hide" :id="triggerInfo.id" :dataType="1" />
    </a-modal>
    <a-modal
      title="成绩列表"
      v-model="scoreinfo.visible"
      width="50%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <ScoreList @callbackMethod="hide" :examinationId="scoreinfo.id" />
    </a-modal>
  </div>
</template>
<script>
import ExaminationInfo from "./examinationInfo.vue"; //添加考试
import PaperList from "./paperslist.vue";
import Trigger from "../element/trigger.vue";
import ScoreList from "./scorelistexam.vue";

const columns = [
  {
    dataIndex: "testName",
    align: "left",
    title: "考试名称",
    ellipsis: true,
  },
  {
    dataIndex: "typeName",
    align: "center",
    title: "考试类型",
    ellipsis: true,
  },
  {
    dataIndex: "questionsCount",
    key: "questionsCount",
    align: "center",
    title: "题目数量",
    width: 120,
  },
  {
    dataIndex: "examPeople",
    key: "examPeople",
    align: "center",
    title: "考试人数",
    width: 120,
  },
  {
    dataIndex: "trigger",
    align: "center",
    title: "考试条件",
    width: 120,
    scopedSlots: { customRender: "trigger" },
  },
  {
    dataIndex: "examStatus",
    align: "center",
    title: "状态",
    width: 120,
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    key: "action",
    width: 290,
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

let _this;
export default {
  name: "examinationlist",
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 292,
      },
      search: {
        //搜索条件
        testName: "",
        examStatus: "",
      },
      selectRows: [], //选中项编号
      isloading: false,
      addedit: {
        title: "",
        visible: false,
        info: {},
      },
      paperinfo: {
        visible: false,
        id: 0,
        limit: 0,
      },
      scoreinfo: {
        visible: false,
        id: 0,
      },
      triggerInfo: {
        visible: false,
        id: 0,
      },
    };
  },
  components: {
    ExaminationInfo,
    PaperList,
    Trigger,
    ScoreList,
  },
  mounted: function () {
    _this = this;
    if (window.innerWidth >= 1600) {
      _this.list.pagination.pageSize = 20;
    }
    window.onresize = () => {
      _this.list.tableHeight = window.innerHeight - 292;
      _this.innerWidth = window.innerWidth;
      if (window.innerWidth >= 1600) {
        _this.list.pagination.pageSize = 20;
      } else {
        _this.list.pagination.pageSize = 10;
      }
    };
    _this.GetList();
  },
  methods: {
    GetList() {
      //获取列表
      _this.isloading = true;
      _this.clearRows();
      _this.$api.examination
        .getPageList(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          _this.search.testName,
          _this.search.examStatus
        )
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.list.datas = res.data.records;
            _this.list.pagination.total = Number(res.data.total);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onPageChange(page) {
      //页面切换回调
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      _this.GetList();
    },
    onRowChange(selectedRowKeys) {
      //table 多选
      _this.selectRows = selectedRowKeys;
    },
    clearRows() {
      //清空选中项
      _this.selectRows = [];
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.GetList();
    },
    showPaper(id, c) {
      _this.paperinfo.visible = true;
      _this.paperinfo.id = id;
      _this.paperinfo.limit = c;
    },
    //查看
    showInfo(info) {
      _this.addedit.title = info.id ? "编辑" : "添加";
      _this.addedit.visible = true;
      _this.addedit.info = info;
    },
    showsScore(id) {
      _this.scoreinfo.visible = true;
      _this.scoreinfo.id = id;
    },
    setTrigger(id) {
      _this.triggerInfo.id = id;
      _this.triggerInfo.visible = true;
    },
    //子组件传值
    save() {
      _this.addedit.visible = false;
      _this.GetList();
    },
    hide() {
      _this.paperinfo.visible = false;
      _this.scoreinfo.visible = false;
      _this.triggerInfo.visible = false;
      _this.GetList();
    },
    delInfo(id) {
      _this.isloading = true;
      _this.$api.examination
        .removeById(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    changeStatusBatch(status) {
      let msg = status == 1 ? "发布" : "结束";
      _this.$confirm({
        title: "提示",
        content: `您确定要${msg}已选择的考试吗？`,
        okText: "确定",
        cancelText: "取消",
        onOk() {
          _this.changeStatus(_this.selectRows.join(","), status);
        },
        onCancel() {},
      });
    },
    changeStatus(id, status) {
      _this.isloading = true;
      _this.$api.examination
        .updateStatus(id, status)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.cang_sta {
  text-align: center;
}

.cang_sta img {
  width: 35px;
}
</style>

