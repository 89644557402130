<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div>
      <a-form-model
        ref="paperForm"
        :model="info"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="试卷名称" prop="testpaperName">
          <a-input
            placeholder="请输入"
            v-model="info.testpaperName"
            :maxLength="50"
          ></a-input>
        </a-form-model-item>
        <!-- <a-form-model-item label="题目分组">
          <a-switch
            v-model="info.groupType"
            checked-children="是"
            un-checked-children="否"
            @change="onGroupTypeChange"
          ></a-switch>
          <a-button
            v-if="info.groupType"
            type="primary"
            @click="showGroupType"
            style="margin-left: 32px"
          >
            新建分组
          </a-button>
        </a-form-model-item> -->
        <a-form-model-item label="出题方式">
          <a-button-group>
            <a-button type="primary" @click="questionChoose">手工添加</a-button>
            <a-button type="primary" @click="ruleSet">随机抽题</a-button>
          </a-button-group>
        </a-form-model-item>
        <a-divider>题目信息</a-divider>
        <a-row :gutter="24">
          <a-col
            v-for="(item, index) in subjectTypes"
            :key="item.type"
            :span="5"
            :offset="index == 0 ? 2 : 0"
          >
            <a-form-model-item
              :label="item.typeName"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 16 }"
            >
              <a-input-number
                placeholder="分值"
                v-model="item.score"
                @change="onScoreChange(item.type, $event)"
              />
              <span> / {{ item.count }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :span="5">
            <a-form-model-item
              label="总分"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 16 }"
            >
              {{ totalScore }}
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-table
          rowKey="subjectId"
          size="small"
          bordered
          :columns="list.columns"
          :pagination="false"
          :dataSource="questions"
          :locale="{ emptyText: '暂无数据' }"
        >
          <template slot="subjectName" slot-scope="subjectName">
            <span v-html="subjectName"></span>
          </template>
          <template slot="subjectType" slot-scope="subjectType">
            <span v-if="subjectType == 0">单选题</span>
            <span v-else-if="subjectType == 1">多选题</span>
            <span v-else>判断题</span>
          </template>
          <template slot="action" slot-scope="info">
            <a-popconfirm
              title="是否确认删除此题目"
              @confirm="removeInfo(info.subjectId)"
            >
              <a-icon slot="icon" type="question-circle-o" />
              <pxkeji-btn name="删除" size="small" type="danger" />
            </a-popconfirm>
          </template>
        </a-table>
        <a-form-model-item
          :wrapper-col="{ span: 10, offset: 4 }"
          style="margin-top: 8px"
        >
          <pxkeji-btn name="保存" type="primary" @click="handleSubmit" />
          <pxkeji-btn
            name="取消"
            type="default"
            @click="cancelClick"
            style="margin-left: 10px"
          />
        </a-form-model-item>
      </a-form-model>
      <a-modal
        title="抽题规则"
        v-model="ruleVisible"
        width="50%"
        :footer="false"
        destroyOnClose
        :maskClosable="false"
      >
        <RuleInfo @callbackMethod="ruleback" />
      </a-modal>

      <a-modal
        title="选择题目"
        v-model="questionList.visible"
        width="80%"
        :dialog-style="{ top: '40px' }"
        :footer="false"
        destroyOnClose
        :maskClosable="false"
      >
        <QuestionList
          @callbackMethod="selQuestionBack"
          :selList="questionList.selList"
          :examinationId="examinationId"
        />
      </a-modal>
      <a-modal
        title="题目分组"
        v-model="groupVisible"
        width="500px"
        :footer="false"
        destroyOnClose
        :maskClosable="false"
      >
        <a-form-model-item label="分组名称">
          <a-input
            placeholder="请输入"
            v-model="info.testpaperName"
            :maxLength="50"
          ></a-input>
        </a-form-model-item>
      </a-modal>
    </div>
  </a-spin>
</template>

<script>
import RuleInfo from "./ruleInfo.vue"; //抽题规则
import QuestionList from "./questionList.vue"; //选题

const columns = [
  {
    dataIndex: "subjectName",
    align: "center",
    ellipsis: true,
    title: "题目内容",
    scopedSlots: { customRender: "subjectName" },
  },
  {
    dataIndex: "categoryName",
    title: "所属分类",
    align: "center",
    width: 160,
  },
  {
    dataIndex: "difficultName",
    title: "难度等级",
    align: "center",
    width: 120,
  },
  {
    dataIndex: "subjectType",
    title: "类型",
    align: "center",
    width: 120,
    scopedSlots: { customRender: "subjectType" },
  },
  {
    title: "操作",
    key: "action",
    width: 120,
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
let _this;
export default {
  name: "papersInfo",
  props: {
    id: String,
    examinationId: String,
    limit: Number,
  },
  data() {
    return {
      isloading: false,
      loadingTip: "加载中...",
      labelCol: { span: 4 },
      wrapperCol: { span: 17 },
      info: {
        examId: this.examinationId,
        testpaperName: "",
        subjectIds: "",
      },
      rules: {
        testpaperName: [
          { required: true, message: "请输入试卷名称", trigger: "blur" },
        ],
      },
      subjectTypes: [
        {
          type: 0,
          typeName: "单选题",
          count: 0,
          score: 0,
        },
        {
          type: 1,
          typeName: "多选题",
          count: 0,
          score: 0,
        },
        {
          type: 2,
          typeName: "判断题",
          count: 0,
          score: 0,
        },
      ],
      totalScore: 0,
      list: {
        columns, //表头
      },
      questions: [], // 题目数据
      ruleVisible: false,
      questionList: {
        visible: false,
        selList: [],
      },
      groupVisible: false,
    };
  },
  components: {
    RuleInfo, //抽题
    QuestionList, //选题
  },
  mounted() {
    _this = this;
    if (_this.id != 0) {
      //编辑
      _this.getInfo();
    }
  },
  methods: {
    /**取消 */
    cancelClick() {
      _this.$emit("callbackMethod");
    },
    onGroupTypeChange() {
      _this.questions = [];
      _this.refreshQuestion();
    },
    showGroupType() {
      _this.groupVisible = true;
    },
    //抽题规则
    ruleSet() {
      _this.ruleVisible = true;
    },
    ruleback() {
      if (arguments.length > 0) {
        let data = arguments[0];
        if (data.length > _this.limit) {
          _this.$message.error(`题目数量不能超过${_this.limit}个`);
          return;
        } else {
          _this.questions = data;
          _this.questions.sort((a, b) => {
            return a.subjectType - b.subjectType;
          });
          _this.refreshQuestion();
        }
      }
      _this.ruleVisible = false;
    },
    //选题
    questionChoose() {
      _this.questionList.visible = true;
      _this.questionList.selList = _this.questions;
    },
    selQuestionBack() {
      if (arguments.length > 0) {
        let data = arguments[0];
        if (data.length > _this.limit) {
          _this.$message.error(`题目数量不能超过${_this.limit}个`);
          return;
        } else {
          _this.questions = data;
          _this.questions.sort((a, b) => {
            return a.subjectType - b.subjectType;
          });
          _this.refreshQuestion();
        }
      }
      _this.questionList.visible = false;
    },
    refreshQuestion() {
      _this.totalScore = 0;
      _this.subjectTypes.forEach((t) => {
        t.count = 0;
        _this.questions.forEach((q) => {
          if (q.subjectType == t.type) {
            t.count++;
            t.score = q.score ? q.score : 0;
          }
        });
        _this.totalScore += t.score * t.count;
      });
    },
    onScoreChange(type, value) {
      _this.questions.forEach((item) => {
        if (item.subjectType == type) {
          item.score = value;
        }
      });
      _this.totalScore = 0;
      _this.subjectTypes.forEach((item) => {
        _this.totalScore += item.score * item.count;
      });
    },
    removeInfo(id) {
      for (let i = 0; i < _this.questions.length; i++) {
        if (_this.questions[i].subjectId == id) {
          _this.questions.splice(i, 1);
          break;
        }
      }
      _this.refreshQuestion();
    },
    /**获取详情 */
    getInfo() {
      _this.isloading = true;
      _this.$api.testPaper
        .getById(_this.id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.info = res.data;
            _this.questions = res.data.subjectItems;
            _this.questions.sort((a, b) => {
              return a.subjectType - b.subjectType;
            });
            _this.refreshQuestion();
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    handleSubmit() {
      _this.$refs.paperForm.validate((valid) => {
        if (valid) {
          if (_this.questions.length != _this.limit) {
            let sub = _this.limit - _this.questions.length;
            let msg = "";
            if (sub > 0) {
              msg = `缺少${sub}个`;
            } else {
              msg = `多出${-sub}个`;
            }
            _this.$message.error(`题目数量应为${_this.limit}个，${msg}`);
            return false;
          }

          let missSubject = [];
          _this.subjectTypes.forEach((item) => {
            if (item.count > 0 && item.score == 0) {
              missSubject.push(item.typeName);
            }
          });
          if (missSubject.length > 0) {
            _this.$message.error(
              `题目类型为 ${missSubject.join(", ")} 的题目未设置分值`
            );
            return false;
          }

          _this.isloading = true;
          _this.loadingTip = "保存中...";
          let subjectIds = [];
          _this.questions.forEach((item, index) => {
            subjectIds.push({
              subjectId: item.subjectId,
              score: item.score,
              sortNum: index + 1,
            });
          });

          let formData = {
            testpaperName: _this.info.testpaperName,
            examId: _this.info.examId,
            subjectIds: JSON.stringify(subjectIds),
          };
          if (_this.id != 0) {
            formData.id = _this.id;
          }
          _this.$api.testPaper
            .saveOrUpdate(formData)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
                _this.$emit("callbackMethod");
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style>
.ant-cascader-menu {
  height: 320px;
}
.questionList {
  margin-bottom: 30px;
}
.ant-table-tbody td p {
  margin: 0;
}
</style>