<template>
  <a-spin :tip="loadingTip" :spinning="isloading" class="cmswrapper">
    <div class="mainWrapper clearfix ruleInfo">
      <div class="articleMeauleft">
        <a-divider orientation="left">题目分类</a-divider>
        <a-tree
          checkable
          showLine
          checkStrictly
          :replaceFields="replaceFields"
          :treeData="treeDataCategory"
          @check="checkCategory"
        />
      </div>
      <div class="articleMainRight">
        <div class="pxkeji-right-box">
          <a-form-model
            ref="ruleForm"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item
              :label="item.subjectTypeName"
              v-for="item in subjectTypes"
              :key="item.subjectId"
            >
              <a-input-number
                placeholder="抽题数量"
                :min="0"
                :max="Number(item.counts)"
                v-model="item.selectCount"
              ></a-input-number>
              <span> / {{ item.counts }}</span>
            </a-form-model-item>
            <a-form-model-item :wrapper-col="{ span: 10, offset: 4 }">
              <pxkeji-btn
                name="抽题并保存"
                type="primary"
                @click="handleSubmit"
              />
              <pxkeji-btn
                name="取消"
                type="default"
                @click="cancelClick"
                style="margin-left: 10px"
              />
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
let _this;
export default {
  name: "ruleInfo",
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 17 },
      treeDataCategory: [],
      replaceFields: { children: "list", title: "name", key: "id" },
      subjectTypes: [],
      selectCategorys: [],
      isloading: false,
      loadingTip: "加载中...",
    };
  },
  mounted() {
    _this = this;
    _this.getTree();
    _this.getTypeCount();
  },
  methods: {
    getTree() {
      _this.$api.questioncategory
        .getTree()
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            res.data.forEach((item) => {
              item.id = Number(item.id);
            });
            _this.treeDataCategory = res.data;
          }
        })
        .catch(() => {});
    },
    /**取消 */
    cancelClick() {
      _this.$emit("callbackMethod");
    },
    checkCategory(checkedKeys) {
      _this.selectCategorys = checkedKeys.checked;
      _this.getTypeCount(checkedKeys.checked.join());
    },
    //试题数量
    getTypeCount(categoryId) {
      _this.$api.subject
        .getTypeCount(categoryId)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.subjectTypes = res.data;
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    handleSubmit() {
      //数据验证
      _this.isloading = true;
      _this.loadingTip = "保存中...";
      let singleCount = 0;
      let multiCount = 0;
      let judgeCount = 0;
      _this.subjectTypes.forEach((item) => {
        if (item.subjectType == 0) {
          singleCount = item.selectCount || 0;
        }
        if (item.subjectType == 1) {
          multiCount = item.selectCount || 0;
        }
        if (item.subjectType == 2) {
          judgeCount = item.selectCount || 0;
        }
      });
      _this.$api.subject
        .getRandomList(
          _this.selectCategorys.join(),
          singleCount,
          multiCount,
          judgeCount
        )
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            let resultList = [];
            res.data.forEach((item) => {
              resultList.push({
                subjectId: item.id,
                subjectName: item.questContent,
                subjectType: item.subjectType,
                difficult: item.difficult,
                difficultName: item.difficultName,
                categoryName: item.categoryName,
              });
            });
            _this.$emit("callbackMethod", resultList);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
  },
};
</script>
<style>
.ant-cascader-menu {
  height: 320px;
}
.mainWrapper {
  box-sizing: border-box !important;
}
.ruleInfo .articleMeauleft {
  width: 40%;
  padding: 0 1%;
  min-height: 600px;
  background: #fff;
  float: left;
}
.ruleInfo .articleMainRight {
  width: 60%;
  float: right;
}
</style>