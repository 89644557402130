<template>
  <div>
    <div>
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="手机号">
              <a-input v-model="where.keyword.telephone" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="考试时间">
              <a-range-picker @change="onTimechange" />
            </a-form-item>
          </a-col>
          <a-col :span="6" :style="{ textAlign: 'left' }">
            <a-button type="primary" @click="searchList">搜索</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="pxkeji-right-box">
      <a-table
        :columns="list.columns"
        rowKey="id"
        :pagination="list.pagination"
        @change="onChange"
        :dataSource="list.datas"
        :loading="isloading"
        :locale="{ emptyText: '暂无数据' }"
      >
        <!-- <span slot="action" slot-scope="info">
          <pxkeji-btn
            name="删除"
            :roles="[]"
            size="small"
            @click="delInfo(info.id)"
            type="default"
          />
        </span> -->
      </a-table>
    </div>
  </div>
</template>
<script>
const columns = [
  {
    dataIndex: "examName",
    key: "examName",
    align: "center",
    title: "考试名称",
  },
  {
    dataIndex: "testpaperName",
    key: "testpaperName",
    align: "center",
    title: "试卷名称",
  },
  {
    dataIndex: "examTime",
    key: "examTime",
    align: "center",
    title: "考试时间",
  },
  {
    dataIndex: "submitTime",
    key: "submitTime",
    align: "center",
    title: "提交时间",
  },
  {
    dataIndex: "userName",
    key: "userName",
    align: "center",
    title: "用户名",
  },
  {
    dataIndex: "score",
    key: "score",
    align: "center",
    title: "成绩",
  },

  // {
  //   title: "操作",
  //   key: "action",
  //   width: 200,
  //   align: "center",
  //   scopedSlots: { customRender: "action" },
  // },
];

var that;
export default {
  name: "storelist",
  props: {
    examinationId: String,
  },
  data() {
    return {
      dis: this.disabled,
      treeData: [],
      expand: false, //高级搜索
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          //分页器
          showQuickJumper: true,
          hideOnSinglePage: true,
          total: 0,
          showTotal: function (total, range) {
            return "共 " + total + " 条";
          },
          defaultCurrent: 1, //页码
          pageSize: 10, //页容
        },
      },
      where: {
        //查询条件
        keyword: {
          //搜索条件
          telephone: "",
          startTime: "",
          endTime: "",
        },
      },
      isloading: false,
      userList: [],
      userListNow: [],
    };
  },
  components: {},
  mounted: function () {
    this.$nextTick(function () {
      that = this;
      this.GetList();
    });
  },
  watch: {
    selectRows: function () {},
  },
  methods: {
    toggle() {
      this.expand = !this.expand;
    },

    onTestchange(e) {
      this.where.keyword.examId = e;
    },

    //用户选择
    userChange(value) {
      if (value) {
        this.dis = false;
        this.getUserlist(value);
        // this.where
        this.where.keyword.userId = value;
      }
    },
    searchChange(s) {
      if (s != "") {
        this.userListNow = [];
        this.$api.user
          .getUserList(1, 10, null, null, null, null, null, null, s)
          .then((res) => {
            this.isloading = false;
            if (res.errorCode == this.$msg.responseCode.success) {
              res.data.records.forEach(function (item, index) {
                this.userListNow.push(item);
              });
            } else {
              that.$message.error(res.errorMsg);
            }
          })
          .catch(() => {
            this.isloading = false;
          });
      }
    },
    GetList() {
      //获取列表
      this.isloading = true;
      this.clearRows();
      this.$api.examination
        .getTrainStudentExamScore(
          this.list.pagination.defaultCurrent,
          this.list.pagination.pageSize,
          this.examinationId,
          this.where.keyword.userId,
          this.where.keyword.startTime,
          this.where.keyword.endTime
        )
        .then((res) => {
          this.isloading = false;
          if (res.errorCode == this.$msg.responseCode.success) {
            this.list.datas = res.data.records;
            this.list.pagination.total = Number(res.data.total);
          } else {
            this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          this.isloading = false;
        });
    },

    onTimechange(date, dateString) {
      this.where.keyword.startTime = dateString[0];
      this.where.keyword.endTime = dateString[1];
    },
    onChange(page) {
      //页面切换回调
      this.list.pagination.defaultCurrent = page.current;
      this.GetList();
    },
    onRowChange(selectedRowKeys) {
      //table 多选
      this.selectRows = selectedRowKeys;
    },
    clearRows() {
      //清空选中项
      this.selectRows = [];
    },
    searchList() {
      this.list.pagination.defaultCurrent = 1;
      this.GetList();
    },
    clear() {
      //清空搜索条件
      for (var info in this.where.keyword) {
        this.where.keyword[info] = "";
      }
    },

    //子组件传值
    save() {
      this.info.visible = false;
      this.GetList();
    },
    close() {
      this.$confirm({
        title: "提示",
        content: "您确定要禁用么",
        okText: "确认",
        cancelText: "取消",
      });
    },
    delInfo(id) {
      this.$confirm({
        title: "您确定要删除此项吗?",
        okText: "确定",
        okType: "danger",
        cancelText: "我再想想",
        onOk() {
          console.log("OK", id);
          that.isloading = true;
          that.$api.examination
            .removeById(id)
            .then((res) => {
              that.isloading = false;
              if (res.errorCode == that.$msg.responseCode.success) {
                that.$message.success(res.errorMsg);
                that.GetList();
              } else {
                that.$message.error(res.errorMsg);
              }
            })
            .catch(() => {
              that.isloading = false;
            });
        },
        onCancel() {
          //console.log("Cancel");
        },
      });
    },
    quitInfo(id) {},
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.cang_sta {
  text-align: center;
}

.cang_sta img {
  width: 35px;
}
</style>

