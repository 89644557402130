<template>
  <a-spin :tip="loadingTip" :spinning="isloading" class="cmswrapper">
    <div>
      <a-form-model
        ref="examinationForm"
        :model="info"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="考试类型" prop="type">
          <a-select v-model="info.type" placeholder="请选择">
            <a-select-option
              v-for="item in examTypeList"
              :value="item.id"
              :key="item.id"
              >{{ item.itemName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="考试名称" prop="testName">
          <a-input
            placeholder="请输入"
            v-model="info.testName"
            :maxLength="50"
          ></a-input>
        </a-form-model-item>
        <!-- <a-form-model-item label="考试模式" prop="examMode">
          <a-radio-group v-model="info.examMode">
            <a-radio :value="1"> 正式考试 </a-radio>
            <a-radio :value="2"> 模拟考试 </a-radio>
          </a-radio-group>
        </a-form-model-item> -->
        <a-row>
          <a-col :span="12">
            <a-form-model-item
              label="题目数量"
              prop="questionsCount"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-input
                placeholder="请输入"
                v-model.number="info.questionsCount"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              label="时间限制"
              prop="minute"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-input
                placeholder="请输入"
                v-model.number="info.minute"
                suffix="分钟"
              ></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item
              label="及格分数线"
              prop="passLine"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-input
                placeholder="请输入"
                v-model.number="info.passLine"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              label="允许考试次数"
              prop="frequency"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 12 }"
              help="注：0 表示不限次数"
            >
              <a-input
                placeholder="请输入"
                v-model.number="info.frequency"
              ></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item label="考试说明">
          <a-textarea
            placeholder="请输入"
            v-model="info.testDescription"
            :rows="4"
            :maxLength="300"
          />
        </a-form-model-item>
        <a-form-model-item label="状态">
          <a-radio-group v-model="info.examStatus">
            <a-radio
              v-for="item in status"
              :key="item.value"
              :value="item.value"
            >
              {{ item.title }}
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 10, offset: 4 }">
          <pxkeji-btn name="保存" type="primary" @click="handleSubmit" />
          <pxkeji-btn
            name="取消"
            type="default"
            @click="cancelClick"
            style="margin-left: 10px"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-spin>
</template>

<script>
let _this;

export default {
  name: "examinationInfo",
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    let checkNumber = (rule, value, callback) => {
      if (value) {
        if (!Number.isInteger(value)) {
          callback(new Error("请输入数字"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      examTypeList: [],
      labelCol: { span: 4 },
      wrapperCol: { span: 17 },
      info: {
        frequency: 0,
        trainGeneral: 0,
        examStatus: 0,
        examMode:2,
      },
      rules: {
        testName: [
          { required: true, message: "请输入考试名称", trigger: "blur" },
        ],
        type: [
          { required: true, message: "请选择考试类型", trigger: "change" },
        ],
        // examMode: [
        //   { required: true, message: "请选择考试模式", trigger: "change" },
        // ],
        questionsCount: [
          { required: true, message: "请输入题目数量", trigger: "blur" },
          { validator: checkNumber, trigger: "change" },
        ],
        minute: [
          { required: true, message: "请输入考试时间限制", trigger: "blur" },
          { validator: checkNumber, trigger: "change" },
        ],
        passLine: [
          { required: true, message: "请输入及格分数线", trigger: "blur" },
          { validator: checkNumber, trigger: "change" },
        ],
        frequency: [
          { required: true, message: "请输入允许考试次数", trigger: "blur" },
          { validator: checkNumber, trigger: "change" },
        ],
      },
      isloading: false,
      loadingTip: "加载中...",
      status: [
        { value: 0, title: "未发布" },
        { value: 1, title: "进行中" },
        { value: 2, title: "已结束" },
      ],
    };
  },
  components: {},
  mounted() {
    _this = this;
    _this.getExamTypeList();
    if (_this.infos.id) {
      _this.getInfo();
    }
  },
  methods: {
    async getExamTypeList() {
      let result = await _this.$api.dictionaryItem.getDictionaryItem(40);
      if (result.errorCode == _this.$msg.responseCode.success) {
        _this.examTypeList = result.data;
      }
    },
    /**取消 */
    cancelClick() {
      _this.$emit("callbackMethod");
    },
    /**获取详情 */
    getInfo() {
      _this.isloading = true;
      _this.$api.examination
        .getById(_this.infos.id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.info = { ...res.data };
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    handleSubmit() {
      _this.$refs.examinationForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.loadingTip = "保存中...";
          let formData = { ..._this.info };
          if (_this.infos.id) {
            formData.id = _this.infos.id;
          }
          //添加
          _this.$api.examination
            .saveOrUpdate(formData)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
                _this.$emit("callbackMethod", res.data);
              } else {
                _this.isloading = false;
                _this.$message.error(res.errorMsg);
              }
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style>
.ant-cascader-menu {
  height: 320px;
}
</style>