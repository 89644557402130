<template>
  <div>
    <div class="pxkeji-right-box">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-item label="试卷名称">
              <a-input placeholder="请输入" v-model="search.testpaperName" />
            </a-form-item>
          </a-col>
          <a-col :span="4" :style="{ textAlign: 'left' }">
            <a-form-item>
              <a-button type="primary" @click="searchList">搜索</a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div>
        <pxkeji-btn
          name="添加"
          class="pxkeji_basic_btn"
          @click="showInfo('0')"
          type="primary"
          :roles="['考试-试卷管理']"
        />
      </div>
    </div>
    <div>
      <a-table
        rowKey="id"
        size="small"
        bordered
        :columns="list.columns"
        :pagination="list.pagination"
        @change="onPageChange"
        :dataSource="list.datas"
        :loading="isloading"
        :locale="{ emptyText: '暂无数据' }"
      >
        <span slot="action" slot-scope="info">
          <pxkeji-btn
            name="编辑"
            :roles="['考试-试卷管理']"
            size="small"
            @click="showInfo(info.id)"
            type="primary"
          />
          <a-popconfirm title="是否确认删除此试卷" @confirm="delInfo(info.id)">
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="删除"
              :roles="['考试-试卷管理']"
              size="small"
              type="danger"
            />
          </a-popconfirm>
        </span>
      </a-table>
    </div>

    <a-modal
      :title="info.title"
      v-model="info.visible"
      width="1100px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <PapersInfo
        @callbackMethod="save"
        :examinationId="examinationId"
        :id="info.id"
        :limit="limit"
      />
    </a-modal>
  </div>
</template>
<script>
import PapersInfo from "./papersInfo.vue"; //添加考试

const columns = [
  {
    dataIndex: "testpaperName",
    align: "center",
    title: "试卷名称",
  },
  {
    title: "操作",
    key: "action",
    width: 240,
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

let _this;
export default {
  name: "paperslist",
  props: {
    examinationId: String,
    limit: Number,
  },
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
      },
      search: {
        //搜索条件
        testpaperName: "",
      },
      isloading: false,
      info: {
        title: "",
        visible: false,
        id: 0,
      },
    };
  },
  components: {
    PapersInfo,
  },
  mounted() {
    _this = this;
    _this.GetList();
  },
  methods: {
    GetList() {
      //获取列表
      _this.isloading = true;
      _this.$api.testPaper
        .getPageListForExam(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          _this.search.testpaperName,
          _this.examinationId
        )
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.list.datas = res.data.records;
            _this.list.pagination.total = Number(res.data.total);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onPageChange(page) {
      //页面切换回调
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      _this.GetList();
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.GetList();
    },
    //查看订单
    showInfo(id) {
      _this.info.title = id ? "编辑" : "添加";
      _this.info.id = id;
      _this.info.visible = true;
    },
    //子组件传值
    save() {
      _this.info.visible = false;
      _this.GetList();
    },
    delInfo(id) {
      _this.isloading = true;
      _this.$api.testPaper
        .removeById(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.cang_sta {
  text-align: center;
}

.cang_sta img {
  width: 35px;
}
</style>

